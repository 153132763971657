import gql from "graphql-tag";

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      code
      success
      message
    }
  }
`;
