import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, Paper, Typography } from "@material-ui/core";

import UpdateButton from "components/UpdateButton";
import { FORGOT_PASSWORD } from "./mutations";
import validationSchema from "./validationSchema";
import { LOGIN_PATH } from "helpers/AuthenticatedRoute";
import { color } from "helpers/style/mixins";

const PaperWrapper = styled(Paper)`
  min-width: 30vw;
  padding: ${({ theme }) => theme.spacing(3)}px;
  max-width: 520px;
`;

const StyledLink = styled(Link)`
  color: ${color("text", "primary")};
`;

const ForgotPasswordForm = ({
  loading,
  success,
  error,
  forgotPasswordErrorMessage,
}) => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <PaperWrapper>
        <Form id="forgotPassword" autoComplete="off">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={3}
          >
            <Grid item spacing={3}>
              <Typography component="h1" variant="h3">
                Mot de passe oublié
              </Typography>
            </Grid>
            {success ? (
              <>
                <Grid item md spacing={3} alignItems="center">
                  <Typography align="center">
                    Votre demande a bien été prise en compte. <br />
                    <br />
                    Un email vous invitant à réinitialiser votre mot de passe a
                    été envoyé à l&apos;adresse renseignée.
                  </Typography>
                </Grid>
                <Grid item md>
                  <StyledLink to={LOGIN_PATH}>
                    Retourner sur la page de connexion
                  </StyledLink>
                </Grid>
              </>
            ) : (
              <Grid item md spacing={3}>
                <Typography>
                  Veuillez saisir votre email pour réinitialiser votre mot de
                  passe.
                </Typography>
              </Grid>
            )}

            {error && (
              <Typography color="error" align="center">
                Il y a eu une erreur, si le problème persiste, veuillez
                contacter un administrateur.
              </Typography>
            )}
            {forgotPasswordErrorMessage && (
              <Typography color="error" align="center">
                {forgotPasswordErrorMessage}
              </Typography>
            )}

            {!success && (
              <>
                <Grid item md>
                  <Field
                    id="username"
                    name="username"
                    label="Email"
                    margin="normal"
                    autoComplete="username"
                    fullWidth
                    component={TextField}
                  />
                </Grid>
                <Grid item md>
                  <UpdateButton isLoading={loading} value="Réinitialiser" />
                </Grid>
                <Grid item md>
                  <StyledLink to={LOGIN_PATH}>
                    Retourner sur la page de connexion
                  </StyledLink>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </PaperWrapper>
    </Grid>
  );
};

ForgotPasswordForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  success: PropTypes.bool,
  forgotPasswordErrorMessage: PropTypes.string,
};

const FormikForgotPasswordForm = () => {
  const [success, setSuccess] = useState(false);
  const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState(
    false
  );
  const [otherError, setOtherError] = useState(false);

  const [forgotPassword, { loading, error }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: ({ forgotPassword }) => {
      if (forgotPassword.success) {
        setSuccess(true);
      } else if (
        !forgotPassword.success &&
        forgotPassword.message &&
        forgotPassword.code !== "500"
      ) {
        setForgotPasswordErrorMessage(forgotPassword.message);
      } else {
        setOtherError(true);
      }
    },
  });

  return (
    <Formik
      initialValues={{
        username: "",
      }}
      onSubmit={(values, actions) => {
        setSuccess(false);
        setOtherError(false);
        setForgotPasswordErrorMessage(false);
        const variables = {
          input: { ...values },
        };
        forgotPassword({ variables })
          .then(() => {
            actions.setSubmitting(false);
          })
          .catch(() => {
            actions.setSubmitting(false);
          });
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <>
            <ForgotPasswordForm
              loading={loading}
              success={success}
              forgotPasswordErrorMessage={forgotPasswordErrorMessage}
              error={error || otherError}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default withRouter(FormikForgotPasswordForm);
