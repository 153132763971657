const TOKEN = "ITM-TOKEN";

const isLocalStorageAvailable = () => {
  return window && window.localStorage;
};

const guardAgainstUnavailableLocalStorage = (callback) => {
  if (!isLocalStorageAvailable()) {
    throw new Error("The localStorage is not available");
  }

  return callback;
};

const getToken = guardAgainstUnavailableLocalStorage(() => {
  return window.localStorage.getItem(TOKEN);
});

const setToken = guardAgainstUnavailableLocalStorage((newToken) => {
  return window.localStorage.setItem(TOKEN, newToken);
});

const removeToken = guardAgainstUnavailableLocalStorage(() => {
  return window.localStorage.removeItem(TOKEN);
});

export default {
  getToken,
  setToken,
  removeToken,
};
