import { createTheme } from "@material-ui/core/styles";
import { red, green, grey, yellow, orange } from "@material-ui/core/colors";

const colors = {
  primary: {
    main: "#20274a",
    dark: "#f9c900",
  },
  secondary: {
    main: "#f9c900",
    dark: "#e6b900",
  },
  tertiary: {
    main: "#f8f7f7",
  },
  red: {
    main: "#ff1744",
  },
};

const sizes = {
  logoWidth: "120px",
  menuTouchWidth: "320px",
  mainNavButtonSize: "65px",
};

export default createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "Open Sans",
      "Helvetica Neue",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontFamily: "Poppins",
      fontSize: "1.728rem",
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h2: {
      fontFamily: "Poppins",
      fontSize: "1.44rem",
      fontWeight: 400,
      lineHeight: 1.3,
    },
    h3: { fontFamily: "Poppins", fontSize: "1.2rem", fontWeight: 400 },
    h4: { fontFamily: "Poppins", fontSize: "1rem" },
    h5: { fontFamily: "Poppins", fontSize: "1rem" },
    h6: { fontFamily: "Poppins", fontSize: "1rem" },
  },
  fontSize: {
    base: "1rem",
    small: "0.9rem",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1300,
      xl: 1920,
    },
  },
  sizes,
  palette: {
    ...colors,
    danger: red,
    lightestGrey: grey[100],
    lightGrey: grey[200],
    altoGrey: grey[300],
    silverGrey: grey[400],
    grey: grey,
    darkGrey: grey[600],
    darkenGrey: grey[700],
    dark: grey[800],
    black: grey[900],
    background: { default: "#fff" },
    table: {
      head: grey[300],
      highlightGrey: grey[500],
      highlightSuccess: green[300],
      highlight: yellow[100],
      highlightFullRow: orange[300],
      highlightDisableRow: grey[400],
      highlightDanger: red[100],
    },
  },
  paletteHover: {
    danger: red[700],
    success: green[700],
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // disable ripple effect globally
    },
  },
  /** Using overrides for common style overrides */
  overrides: {
    MuiPickersDay: {
      current: {
        color: colors.secondary.main,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: colors.secondary.main,
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: colors.secondary.main,
      },
      noPoint: {
        backgroundColor: colors.secondary.main,
      },
      thumb: {
        backgroundColor: colors.secondary.main,
        border: `14px solid ${colors.secondary.main}`,
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: colors.secondary.main,
      },
    },
    MuiTableCell: {
      head: {
        color: "white",
        backgroundColor: colors.primary.main,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.primary.main,
        "&:hover": {
          color: colors.secondary.main,
          backgroundColor: "transparent",
        },
      },
    },
  },
});
