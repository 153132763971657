import React from "react";
import { Route } from "react-router-dom";
import Config from "config";

import UnderConstruction from "./UnderConstruction";

const routesFromConfig = Config.get("underConstructionRoutes");
const path = Array.isArray(routesFromConfig)
  ? routesFromConfig.map((route) => route)
  : routesFromConfig;

export default [
  <Route
    exact
    path={path}
    key="under-construction"
    component={UnderConstruction}
  />,
];
