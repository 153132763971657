import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const CreateAreasPage = lazyWithRetry(() => import("./CreateAreasPage"));
const EditAreasPage = lazyWithRetry(() => import("./EditAreasPage"));

export default [
  <Route
    exact
    path="/settings/areas/create"
    key="areas-create"
    component={CreateAreasPage}
  />,
  <Route
    exact
    path="/settings/areas/edit/:id"
    key="areas-edit"
    component={EditAreasPage}
  />,
];
