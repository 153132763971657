import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CircularProgress, Button, Tooltip } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { th } from "../../helpers/style/mixins";

const ButtonCustom = styled(Button)`
  width: 11rem;
`;

const ButtonCustomSticky = styled(ButtonCustom)`
  box-shadow: ${th("shadows[8]")};
`;

const UpdateButton = ({
  isLoading,
  isDisabled = false,
  isSticky = false,
  value,
  onClick,
  onDisableClick,
  onDisableClickTooltipText = "",
}) => {
  const ButtonComponent = isSticky ? ButtonCustomSticky : ButtonCustom;

  if (typeof onDisableClick === "function" && isDisabled) {
    return (
      <Tooltip title={onDisableClickTooltipText}>
        <span
          /**
           * If the user still click on the disabled button we still fire the form validation for the purpose of highlighting form's errors.
           * This allows less experienced users to understand what is missing in the form.
           */
          onClick={(e) => onDisableClick(e)}
        >
          <ButtonComponent
            variant="contained"
            color="primary"
            type="submit"
            disabled={isDisabled || isLoading}
            onClick={onClick}
            endIcon={!isLoading && <SaveIcon />}
          >
            {isLoading ? <CircularProgress color="primary" size={24} /> : value}
          </ButtonComponent>
        </span>
      </Tooltip>
    );
  }
  return (
    <ButtonComponent
      variant="contained"
      color="primary"
      type="submit"
      disabled={isDisabled || isLoading}
      onClick={onClick}
      endIcon={!isLoading && <SaveIcon />}
    >
      {isLoading ? <CircularProgress color="primary" size={24} /> : value}
    </ButtonComponent>
  );
};

UpdateButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDisableClick: PropTypes.func,
  onDisableClickTooltipText: PropTypes.string,
};

export default UpdateButton;
