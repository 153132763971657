import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const urbantzPage = lazyWithRetry(() => import("./UrbantzPage"));

export default [
  <Route
    exact
    path="/settings/urbantz"
    key="urbantz"
    component={urbantzPage}
  />,
];
