import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import {
  Select,
  FormControl,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@material-ui/core";

import { GET_OFFICES_SELECT } from "./queries";
import useOffice from "components/Offices/useOffice";
import ErrorMessageCustom from "components/ErrorMessageCustom";

const findOffice = (event, offices) => {
  return offices.find((office) => office.id === event.target.value);
};

const OfficeSelect = ({ htmlId }) => {
  const [officeSelectValue, setOfficeSelectValue, clear] = useOffice();
  const { loading, error, data } = useQuery(GET_OFFICES_SELECT);

  if (error) {
    return <ErrorMessageCustom error="Liste des agences indisponible" />;
  }

  if (loading) {
    return null;
  }

  const offices = data.offices.data;
  const hasManyOffices = offices && offices.length > 1;
  const hasSelectedOffice = officeSelectValue && officeSelectValue.id;
  const hasOneOffice = offices && offices.length > 0;

  if (offices && offices.length === 0) {
    clear();
  }
  if (!hasManyOffices && !hasSelectedOffice && hasOneOffice) {
    setOfficeSelectValue(offices[0]);
  }

  return hasManyOffices ? (
    <FormControl className="info__office" variant="filled">
      <label
        className="info-office__label"
        htmlFor={htmlId || "office-select-navbar"}
      >
        Agence
      </label>
      <Select
        name="office"
        disabled={loading}
        value={officeSelectValue.id || ""}
        onChange={(event) => {
          const office = findOffice(event, offices);
          if (office) {
            setOfficeSelectValue(office);
          }
        }}
        displayEmpty
        input={
          <OutlinedInput name="office" id={htmlId || "office-select-navbar"} />
        }
      >
        {offices.map((office) => (
          <MenuItem key={office.id} value={office.id}>
            {office.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <Typography>
      {offices?.length
        ? offices[0].name
        : loading
        ? null
        : "Agence indisponible"}
    </Typography>
  );
};

OfficeSelect.propTypes = {
  htmlId: PropTypes.string,
};

export default OfficeSelect;
