import { matchPath } from "react-router";

export const userRoles = {
  CONTRACTOR: "contractor",
  USER: "user",
  MANAGER: "manager",
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
};

export const isContractor = (profile) => profile === userRoles.CONTRACTOR;
export const isUser = (profile) => profile === userRoles.USER;
export const isManager = (profile) => profile === userRoles.MANAGER;
export const isAdmin = (profile) =>
  profile === userRoles.ADMIN || profile === userRoles.SUPER_ADMIN;

export const isForbidden = (errors) =>
  errors.filter((error) => error.extensions.code === "FORBIDDEN").length > 0;

const contractorAuthorizedRoutes = [
  "/",
  "/orders",
  "/orders/create",
  "/orders/edit/:id",
  "/orders/recap/:id",
];

const userAuthorizedRoutes = [
  ...contractorAuthorizedRoutes,
  "/office-planning",
  "/roadmaps/billing",
  "/settings/offices/areas",
  "/settings/contractors",
  "/settings/contractors/view/:id",
  "/settings/articles",
  "/settings/article-lists",
  "/settings/planning",
  "/orders/create/advanced",
  "/orders/create/advanced/:id?",
  "/settings/business-days",
  "/settings/trucklines",
  "/settings/trucklines/edit/:id",
  "/settings/users/me",
  "/rounds",
  "/rounds/:officeId/:date/:halfDay",
  "/roadmaps/billing",
  "/roadmaps/billing/:date/:truckLineId",
];

const managerAuthorizedRoutes = [
  ...userAuthorizedRoutes,
  "/settings/users",
  "/settings/users/edit/:id",
  "/settings/users/create",
  "/settings/postcodes",
  "/settings/contractors/edit/:id",
  "/settings/postcodes/create",
  "/settings/postcodes/edit/:id",
  "/settings/areas/create",
  "/settings/areas/edit/:id",
  "/settings/trucks",
  "/settings/trucks/create",
  "/settings/trucks/edit/:id",
  "/settings/planning-changes",
];

const adminAuthorizedRoutes = ["*"];

export const isAuthorizedRoutes = (location, profile) => {
  let authorizedRoutes = [];

  switch (profile) {
    case userRoles.CONTRACTOR:
      authorizedRoutes = contractorAuthorizedRoutes;
      break;
    case userRoles.USER:
      authorizedRoutes = userAuthorizedRoutes;
      break;
    case userRoles.MANAGER:
      authorizedRoutes = managerAuthorizedRoutes;
      break;
    case userRoles.ADMIN:
      authorizedRoutes = adminAuthorizedRoutes;
      break;
    case userRoles.SUPER_ADMIN:
      authorizedRoutes = adminAuthorizedRoutes;
      break;
    default:
      authorizedRoutes = contractorAuthorizedRoutes;
      break;
  }

  return (
    authorizedRoutes.includes(location.pathname) ||
    authorizedRoutes.includes("*") ||
    authorizedRoutes.find((route) => {
      return Boolean(
        matchPath(location.pathname, {
          path: route,
          strict: true,
          exact: true,
        })
      );
    })
  );
};
