import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import tokenProvider from "core/tokenProvider";
import { isAuthorizedRoutes } from "./userRoles";
import useAppState from "./useAppState";

export const LOGIN_PATH = "/login";

const AuthenticatedRoute = ({ children }) => {
  const location = useLocation();
  const userProfile = useAppState().user.profile;
  const hasToken = tokenProvider.getToken();

  if (!hasToken) {
    return <Redirect to={LOGIN_PATH} />;
  }

  if (!isAuthorizedRoutes(location, userProfile)) {
    return <Redirect to="/" />;
  }

  return children;
};

export default AuthenticatedRoute;
