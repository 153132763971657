import { detect } from "detect-browser";

const BROWSER_LIMIT_VERSION = {
  firefox: 59,
  chrome: 64,
  opera: 51,
  safari: 11,
};

export const hasValidBrowser = () => {
  const browserData = detect();
  const version = browserData.version.includes(".")
    ? +browserData.version.split(".")[0]
    : +browserData.version;

  if (
    browserData.name === "ie" ||
    (browserData.name === "safari" && browserData.os.includes("Windows")) ||
    browserData.os === "Windows XP"
  ) {
    return false;
  }

  if (browserData.name === "edge" || browserData.name === "edge-chromium") {
    return true;
  }

  return version > BROWSER_LIMIT_VERSION[browserData.name];
};

export const getBrowserVersion = () =>
  detect().name.concat(" ", detect().version);
