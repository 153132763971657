import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import underConstructionImg from "./under-construction.jpg";

const UnderConstruction = ({ location }) => {
  const center = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={center}>
      <p>
        Page <strong>{location.pathname}</strong> en cours de développement
      </p>
      <img style={{ maxWidth: "100%" }} src={underConstructionImg} alt="" />
    </div>
  );
};

UnderConstruction.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(UnderConstruction);
