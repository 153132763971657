import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexRoadmapsBillingPage = lazyWithRetry(() =>
  import("./IndexRoadmapsBillingPage")
);
const ViewRoadmapsBillingPage = lazyWithRetry(() =>
  import("./ViewRoadmapsBillingPage")
);

export default [
  <Route
    exact
    path="/roadmaps/billing"
    key="roadmaps-billing-index"
    component={IndexRoadmapsBillingPage}
  />,
  <Route
    exact
    path="/roadmaps/billing/:date/:truckLineId"
    key="roadmap-billing-view"
    component={ViewRoadmapsBillingPage}
  />,
];
