import React from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";

export const FORGOT_PASSWORD_PATH = "/forgot-password";

const ForgotPasswordPage = () => {
  return <ForgotPasswordForm />;
};

export default ForgotPasswordPage;
