import React from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import useOffice from "components/Offices/useOffice";

const LogOutUser = ({ children }) => {
  const [, , clearOffice] = useOffice();
  const client = useApolloClient();
  const logout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    clearOffice();
    client.clearStore();
  };

  return <>{children(logout)}</>;
};

LogOutUser.propTypes = {
  children: PropTypes.func.isRequired,
};

export default LogOutUser;
