import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexOrdersPage = lazyWithRetry(() => import("./IndexOrdersPage"));
const CreateOrdersPage = lazyWithRetry(() => import("./CreateOrdersPage"));
const EditOrdersPage = lazyWithRetry(() => import("./EditOrdersPage"));
const RecapOrderPage = lazyWithRetry(() => import("./RecapOrderPage"));
const AdvancedCreateOrdersPage = lazyWithRetry(() =>
  import("./AdvancedCreateOrdersPage")
);

export default [
  <Route exact path="/orders" key="orders-index" component={IndexOrdersPage} />,
  <Route
    exact
    path="/orders/create"
    key="orders-create"
    component={CreateOrdersPage}
  />,
  <Route
    exact
    path="/orders/create/advanced/:id?"
    key="orders-create-advanced"
    component={AdvancedCreateOrdersPage}
  />,
  <Route
    exact
    path="/orders/edit/:id"
    key="orders-edit"
    component={EditOrdersPage}
  />,
  <Route
    exact
    path="/orders/recap/:id"
    key="orders-recap"
    component={RecapOrderPage}
  />,
];
