import React from "react";
import lazyWithRetry from "helpers/lazyWithRetry";
import { Route } from "react-router-dom";
import { ME_PATH } from "components/Navbar/ConnectedUser";

const IndexUsersPage = lazyWithRetry(() => import("./IndexUsersPage"));
const EditUsersPage = lazyWithRetry(() => import("./EditUsersPage"));
const CreateUsersPage = lazyWithRetry(() => import("./CreateUsersPage"));
const EditCurrentUserInfoPage = lazyWithRetry(() =>
  import("./EditCurrentUserInfoPage")
);

export default [
  <Route
    exact
    path="/settings/users"
    key="users-index"
    component={IndexUsersPage}
  />,
  <Route
    exact
    path="/settings/users/edit/:id"
    key="users-edit"
    component={EditUsersPage}
  />,
  <Route
    exact
    path="/settings/users/create"
    key="user-create"
    component={CreateUsersPage}
  />,
  <Route exact path={ME_PATH} key="me" component={EditCurrentUserInfoPage} />,
];
