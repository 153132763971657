import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexTrucksPage = lazyWithRetry(() => import("./IndexTrucksPage"));
const CreateTrucksPage = lazyWithRetry(() => import("./CreateTrucksPage"));
const EditTrucksPage = lazyWithRetry(() => import("./EditTrucksPage"));

export default [
  <Route
    exact
    path="/settings/trucks"
    key="trucks-index"
    component={IndexTrucksPage}
  />,
  <Route
    exact
    path="/settings/trucks/create"
    key="trucks-create"
    component={CreateTrucksPage}
  />,
  <Route
    exact
    path="/settings/trucks/edit/:id"
    key="trucks-edit"
    component={EditTrucksPage}
  />,
];
