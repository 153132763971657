import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Divider,
  List,
  ListItem,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box,
  Grid,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  Notes as NotesIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import MenuTouchItem from "./MenuTouchItem";
import LogoutLink from "./LogoutLink";
import OfficeSelect from "./OfficeSelect";
import ConnectedUser from "./ConnectedUser";
import LogOutUser from "helpers/LogOutUser";
import {
  MenuTouchDrawer,
  ListItemIcon,
  ListItemText,
  AccordionPanel,
  NavbarGrid,
} from "./styled";
import { menuLabels } from "./menuItems";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const MenuTouch = ({ open, handleDrawerClose, menuItems }) => {
  return (
    <MenuTouchDrawer variant="persistent" anchor="right" open={open}>
      <div className="drawerHeader">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <ConnectedUser />
          </Grid>
        </Grid>
      </div>

      <Box m={2}>
        <NavbarGrid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            {format(Date.now(), "'Semaine' I", { locale: fr })}
            &nbsp;&nbsp;&nbsp;
          </Grid>
          <Grid item className="info">
            <OfficeSelect htmlId="menutouch-office-selector" />
          </Grid>
        </NavbarGrid>
      </Box>

      <Divider />

      <List>
        {menuItems.mainNav.map(
          ({ title, href, icon, isNavLink }) =>
            isNavLink && (
              <Link
                className="menu-touch-link"
                key={`${title}-touch`}
                to={href}
                onClick={handleDrawerClose}
              >
                <MenuTouchItem button text={title} icon={icon} />
              </Link>
            )
        )}
        {!!menuItems.settingsLinks.length && (
          <ListItem key={menuLabels.settings}>
            <AccordionPanel>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={menuLabels.settings.toUpperCase()} />
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {menuItems.settingsLinks.map(({ text, href }) => (
                    <Link
                      className="menu-touch-link"
                      to={href}
                      key={text}
                      onClick={handleDrawerClose}
                    >
                      <MenuTouchItem button text={text} />
                    </Link>
                  ))}
                </List>
              </AccordionDetails>
            </AccordionPanel>
          </ListItem>
        )}
        <Divider />
        {!!menuItems.status.length && (
          <ListItem key={menuLabels.logs}>
            <AccordionPanel>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panel1b-header"
              >
                <ListItemIcon>
                  <NotesIcon />
                </ListItemIcon>
                <ListItemText primary={menuLabels.logs.toUpperCase()} />
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {menuItems.status.map(({ text, href }) => (
                    <Link
                      className="menu-touch-link"
                      key={`${text}-touch`}
                      to={href}
                      onClick={handleDrawerClose}
                    >
                      <MenuTouchItem button text={text} />
                    </Link>
                  ))}
                </List>
              </AccordionDetails>
            </AccordionPanel>
          </ListItem>
        )}
        <Divider />
        <LogOutUser>
          {(logout) => (
            <LogoutLink
              logout={logout}
              loginPage={"/login"}
              onClick={handleDrawerClose}
            >
              <MenuTouchItem
                button
                text={menuLabels.logout.toUpperCase()}
                icon={<CancelIcon />}
              />
            </LogoutLink>
          )}
        </LogOutUser>
      </List>
    </MenuTouchDrawer>
  );
};

MenuTouch.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  menuItems: PropTypes.object.isRequired,
};

export default MenuTouch;
