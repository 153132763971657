import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { LinkButton } from "components/Navbar/styled";

const LogoutLink = ({ logout, button, loginPage, children }) =>
  button ? (
    <LinkButton to={loginPage} onClick={logout}>
      {children}
    </LinkButton>
  ) : (
    <Link className="menu-touch-link" to={loginPage} onClick={logout}>
      {children}
    </Link>
  );

LogoutLink.propTypes = {
  logout: PropTypes.func.isRequired,
  button: PropTypes.bool,
  loginPage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LogoutLink;
