import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexPlanningPage = lazyWithRetry(() => import("./IndexPlanningPage"));

export default [
  <Route
    exact
    path="/settings/planning"
    key="planning-index"
    component={IndexPlanningPage}
  />,
];
