import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexRoundPage = lazyWithRetry(() => import("./IndexRoundPage"));
const EditRoundPage = lazyWithRetry(() => import("./EditRoundPage"));

export default [
  <Route exact path="/rounds" key="rounds-index" component={IndexRoundPage} />,
  <Route
    exact
    path="/rounds/:officeId/:date/:halfDay"
    key="rounds-edit"
    component={EditRoundPage}
  />,
];
