import React from "react";
import PropTypes from "prop-types";
import { LogoWrapper } from "./styled";
import { Link } from "react-router-dom";

const Logo = ({ src, alt = "" }) => {
  return (
    <LogoWrapper>
      <Link className="logoLink" to="/">
        <img className="logoImg" src={src} alt={alt} />
      </Link>
    </LogoWrapper>
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Logo;
