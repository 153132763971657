import React from "react";
import PropTypes from "prop-types";
import BoxError from "components/shared/BoxError";

const ErrorMessageCustom = ({
  error = "Une erreur est survenue",
  textAlign = "left",
}) => <BoxError textAlign={textAlign}>{error}</BoxError>;

ErrorMessageCustom.propTypes = {
  error: PropTypes.string,
  textAlign: PropTypes.string,
};

export default ErrorMessageCustom;
