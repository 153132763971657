import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import { ListItemIcon, ListItemText } from "./styled";

const MenuTouchItem = ({ text, icon, button = false }) => {
  return (
    <ListItem button={button} key={text}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={text.toUpperCase()} />
    </ListItem>
  );
};

MenuTouchItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  button: PropTypes.bool,
};

export default MenuTouchItem;
