import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Grid, Paper, Typography } from "@material-ui/core";
import { color } from "helpers/style/mixins";

import UpdateButton from "components/UpdateButton";
import { RESET_PASSWORD } from "./mutations";
import validationSchema from "./validationSchema";
import { LOGIN_PATH } from "helpers/AuthenticatedRoute";

const PaperWrapper = styled(Paper)`
  min-width: 30vw;
  padding: ${({ theme }) => theme.spacing(3)}px;
  max-width: 520px;
`;

const StyledLink = styled(Link)`
  color: ${color("text", "primary")};
`;

const GridWithMargin = styled(Grid)`
  margin-top: 15px;
`;

const ResetPasswordForm = ({
  loading,
  success,
  error,
  resetPasswordErrorMessage,
  isInitialization,
}) => {
  const pageTitle = isInitialization
    ? "Initialiser le mot de passe"
    : "Réinitialiser le mot de passe";

  const btnSubmitLabel = isInitialization ? "Initialiser" : "Réinitialiser";

  return (
    <Grid container justifyContent="center" alignItems="center">
      <PaperWrapper>
        <Form id="resetPassword" autoComplete="off">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            spacing={3}
          >
            <Grid item md>
              <Typography component="h1" variant="h3">
                {pageTitle}
              </Typography>
            </Grid>
            {error && (
              <Typography color="error">
                Il y a eu une erreur, si le problème persiste, veuillez
                contacter un administrateur.
              </Typography>
            )}
            {resetPasswordErrorMessage && (
              <Typography color="error">{resetPasswordErrorMessage}</Typography>
            )}
            {success ? (
              <>
                <Grid item md spacing={3} alignItems="center">
                  <Typography align="center">
                    Votre mot de passe a été modifié avec succès ! <br />
                  </Typography>
                </Grid>
                <Grid item md>
                  <StyledLink to={LOGIN_PATH}>
                    Retourner sur la page de connexion
                  </StyledLink>
                </Grid>
              </>
            ) : (
              <>
                <Grid item md>
                  <Field
                    id="password"
                    name="password"
                    label="Nouveau mot de passe"
                    type="password"
                    margin="normal"
                    component={TextField}
                    fullWidth
                  />
                </Grid>
                <Grid item md>
                  <Field
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    label="Confirmation mot de passe"
                    type="password"
                    margin="normal"
                    component={TextField}
                    fullWidth
                  />
                </Grid>
                <GridWithMargin item md>
                  <UpdateButton isLoading={loading} value={btnSubmitLabel} />
                </GridWithMargin>
                <Grid item md>
                  <StyledLink to={LOGIN_PATH}>
                    Retourner sur la page de connexion
                  </StyledLink>
                </Grid>
              </>
            )}
          </Grid>
        </Form>
      </PaperWrapper>
    </Grid>
  );
};

ResetPasswordForm.propTypes = {
  isInitialization: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  resetPasswordErrorMessage: PropTypes.string,
};

const FormikResetPasswordForm = (props) => {
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState(
    null
  );
  const [success, setSuccess] = useState(false);
  const [otherError, setOtherError] = useState(false);

  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ resetPassword }) => {
      if (resetPassword.success) {
        setSuccess(true);
      } else if (!resetPassword.success && resetPassword.message) {
        setResetPasswordErrorMessage(resetPassword.message);
      } else {
        setOtherError(true);
      }
    },
  });

  const token = props?.match?.params?.token;
  const isInitialization = props?.isInitialization;

  return (
    <Formik
      initialValues={{
        password: "",
        passwordConfirmation: "",
      }}
      onSubmit={({ password }, actions) => {
        setSuccess(false);
        setOtherError(false);
        setResetPasswordErrorMessage(null);
        const variables = {
          input: { password, token, isInitialization },
        };
        resetPassword({ variables })
          .then(() => {
            actions.setSubmitting(false);
          })
          .catch(() => {
            actions.setSubmitting(false);
          });
      }}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <>
            <ResetPasswordForm
              loading={loading}
              resetPasswordErrorMessage={resetPasswordErrorMessage}
              success={success}
              error={error || otherError}
              isInitialization={isInitialization}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default withRouter(FormikResetPasswordForm);
