import { useState } from "react";

const useOffice = () => {
  const storedOffice = localStorage.getItem("office");
  let lastOffice = {};
  try {
    lastOffice = JSON.parse(storedOffice);
  } catch (error) {
    lastOffice = {};
  }
  const [currentOffice, setCurrentOffice] = useState(lastOffice || {});

  const setOffice = (office, reload = true) => {
    if (office && (office.clear || (office.id && office.name))) {
      setCurrentOffice(office);
      localStorage.setItem("office", JSON.stringify(office));
      if (reload) {
        window.location.reload();
      }
    }
  };

  const clear = () => {
    localStorage.setItem("office", JSON.stringify({}));
  };

  return [currentOffice, setOffice, clear];
};

export default useOffice;
