import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { Alert as BaseAlert } from "@material-ui/lab";
import PropTypes from "prop-types";

const StyledAlert = styled(BaseAlert)`
  display: inline-flex;
`;
const Alert = ({ children, severity }) => (
  <StyledAlert severity={severity}>{children}</StyledAlert>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(["error", "warning", "info", "success"]),
};

const BoxAlert = ({ children, severity, ...boxProps }) => {
  return (
    <Box {...boxProps}>
      <Alert severity={severity}>{children}</Alert>
    </Box>
  );
};

BoxAlert.propTypes = Alert.propTypes;

export default BoxAlert;
