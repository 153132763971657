import gql from "graphql-tag";
import Config from "config";

export const GET_OFFICES_SELECT = gql`
  query {
    offices(limit: ${Config.get("pageLimit.noLimit")}) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_USER_OFFICES = gql`
  query getUserOffices {
    me {
      id
      profile
      offices {
        id
        name
      }
    }
  }
`;
