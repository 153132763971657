import React from "react";
import { Redirect, Route } from "react-router-dom";
import tokenProvider from "core/tokenProvider";

export const HOME_PATH = "/";

const UnauthenticatedRoute = (routeProps) => {
  const hasToken = tokenProvider.getToken();

  if (hasToken) {
    return <Redirect to={HOME_PATH} />;
  }

  return <Route {...routeProps} />;
};

export default UnauthenticatedRoute;
