import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexOfficePlanningPage = lazyWithRetry(() =>
  import("./IndexOfficePlanningPage")
);

export default [
  <Route
    exact
    path="/office-planning"
    key="office-planning-index"
    component={IndexOfficePlanningPage}
  />,
];
