import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import tokenProvider from "core/tokenProvider";

/* Actions Types */
const SET_NOTIF_SUCCESS = "SET_NOTIF_SUCCESS";
const SET_NOTIF_ERROR = "SET_NOTIF_ERROR";
const SHOW_NOTIF = "SHOW_NOTIF";
const HIDE_NOTIF = "HIDE_NOTIF";
const RESET_NOTIF = "RESET_NOTIF";

export const AppStateContext = createContext();
export const AppDispatchContext = createContext();

const decodedJwt =
  tokenProvider.getToken() && jwt_decode(tokenProvider.getToken());

const initialState = {
  user: {
    profile: decodedJwt?.profile,
    identity: decodedJwt?.identity,
    id: decodedJwt?.sub,
  },
  notif: {
    show: null,
    success: null,
    error: null,
  },
};

const appReducer = (state, action) => {
  switch (action.type) {
    case SET_NOTIF_SUCCESS:
      return {
        ...state,
        notif: { ...state.notif, show: true, success: action.payload },
      };
    case SET_NOTIF_ERROR:
      return {
        ...state,
        notif: { ...state.notif, show: true, error: action.payload },
      };
    case SHOW_NOTIF:
      return {
        ...state,
        notif: { ...state.notif, show: true },
      };
    case HIDE_NOTIF:
      return {
        ...state,
        notif: { ...state.notif, show: false },
      };
    case RESET_NOTIF:
      return initialState;
    default:
      return state;
  }
};

const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AppContextProvider;
