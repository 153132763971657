import _get from "lodash.get";
import appConfig from "./app.config";

let config = null;

const globalConfig = {
  clientPortal: {
    appointmentType: {
      clientPortal: "CLIENT_PORTAL",
      store: "STORE",
    },
  },
  underConstructionRoutes: [
    "/settings/planning-changes",
    "/logs/sms",
    "/logs/wavesoft",
    "/account",
  ],
  sentryUrlKey: "https://c5611bfe2c8144c6b388cca600dc6ffa@sentry.io/1490759",
  apolloUri: "/graphql",
  pageLimit: {
    default: 20,
    noLimit: -1,
  },
  apollo: {
    httpCodeExeptionPath: "graphQLErrors.0.extensions.response.status",
  },
  user: {
    profiles: {
      super_admin: "Super Admin",
      admin: "Admin",
      contractor: "Donneur d'ordre",
      user: "Collaborateur",
      manager: "Manager",
    },
    civilities: {
      M: "M",
      Mme: "Mme",
      Mlle: "Mlle",
    },
    jobTitles: {
      aucun: "Aucun",
      caisse: "Caisse",
      depot: "Dépôt",
      sav: "SAV",
      direction: "Direction",
      chef_de_rayon_meuble: "Chef de rayon meuble",
      chef_de_rayon_menager: "Chef de rayon ménager",
      responsable_administratif: "Responsable Administratif",
      other: "Autre",
    },
  },
  halfDays: {
    morning: "Matin",
    afternoon: "Après-midi",
  },
  days: {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
  },
  office: {
    defaultNextDayShiftHour: "12:00:00",
    defaultAfternoonStartHour: "13:00:00",
    defaultExceptionalAreaStart: "09:00:00",
    defaultExceptionalAreaEnd: "17:00:00",
  },
  areas: {
    schedules: {
      defaultStartTime: "09:00:00",
      defaultEndTime: "17:00:00",
    },
  },
  dataTable: {
    highlightRowLabels: {
      forcing: "Forçage",
    },
  },
  multiselect: {
    messages: {
      default: {
        searchPlaceholder: "Rechercher...",
        selectAllMessage: "Tout sélectionner",
        clearAllMessage: "Tout déselectionner",
        noneSelectedMessage: "Aucune sélection",
        selectedMessage: "sélectionné(s)",
      },
      custom: {
        singularSelectedMessage: "sélectionné",
        pluralSelectedMessage: "sélectionnés",
      },
    },
  },
  regex: {
    frenchPhoneNumber: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  },
  notifDefaultDuration: 4000,
  dateFormat: {
    input: "yyyy-MM-dd'T'HH:mm:ssXXX",
    output: "yyyy-MM-dd",
  },
  defaultArticleListId: "f7c37732-448f-4e7e-a716-15be7ea88d5b",
  maxArticlesInPopper: 5,
  orderStatus: {
    validated: { code: "VALIDATED", label: "Validée" },
    pending: { code: "PENDING", label: "À traiter" },
    categorize: {
      code: "WAITING_FOR_CATEGORIZE",
      label: "Article à catégoriser",
    },
    externalPending: { code: "EXTERNAL_WAITING", label: "À traiter - externe" },
    manager: { code: "WAITING_FOR_MANAGER", label: "À traiter par le manager" },
    review: { code: "TO_REVIEW", label: "À relancer / message" },
    customer: {
      code: "WAITING_FOR_CUSTOMER",
      label: "À traiter par le client",
    },
    cancellation: {
      code: "WAITING_CANCELLATION",
      label: "Demande d'annulation",
    },
    cancelled: { code: "CANCELLED", label: "Annulée" },
  },
  billingStatus: {
    all: { code: "ALL", label: "Toutes" },
    synced: { code: "SYNCED", label: "Synchronisé" },
    notsynced: { code: "NOTSYNCED", label: "Non synchronisé" },
  },
  delivery: {
    greetings: 5,
    journey_time: 10,
  },
  googleMap: {
    api: "AIzaSyCPQ-mWkw-QJAiIsfx23XnY-5-uWhw3vsE",
    colors: [
      "#000000",
      "#8b4513",
      "#ff0000",
      "#ffa600",
      "#ffff00",
      "#008000",
      "#0000ff",
      "#800080",
      "#808080",
      "#ffffff",
      "#ff00ff",
      "#b8870b",
      "#00ff00",
      "#00ffff",
      "#808000",
    ],
  },
  pdfTypes: {
    confirmation: {
      endpoint: "orders/pdfOrderConfirmations/",
      filename: "confirmations-commandes.pdf",
    },
    short: {
      endpoint: "orders/pdfShort/",
      filename: "pdf-succinct.pdf",
    },
    brands: {
      endpoint: "orders/pdfBrands/",
      filename: "pdf-enseigne.pdf",
    },
    detailed: {
      endpoint: "orders/pdfDetailed/",
      filename: "pdf-detail.pdf",
    },
    kpi: {
      endpoint: "orders/kpi/",
      filename: "export_but_kpi.xlsx",
    },
  },
  billingConforamaEnabled: true,
  isWavesoftEnabled: false,
  isUrbantzEnabled: true,
  picking: {
    status: {
      PICKUP_SUCCESSFUL: {
        value: "PICKUP_SUCCESSFUL",
        label: "Prélèvement réussi",
      },
      PICKUP_OK: {
        value: "PICKUP_OK",
        label: "Prélèvement réussi",
      },
      PICKUP_FAILED: { value: "PICKUP_FAILED", label: "Prélèvement échoué" },
      PICKUP_PARTIALLY: {
        value: "PICKUP_PARTIALLY",
        label: "Prélèvement partiel",
      },
    },
    incidents: {
      DAMAGED_PACKAGING_OR_PRODUCT: {
        value: "DAMAGED_PACKAGING_OR_PRODUCT",
        label: "Emballage/produit abîmé",
      },
      MISSING_PRODUCT: { value: "MISSING_PRODUCT", label: "Produit manquant" },
      PRODUCT_OR_EAN_DOES_NOT_MATCH: {
        value: "PRODUCT_OR_EAN_DOES_NOT_MATCH",
        label: "Produit/EAN ne correspond pas",
      },
      FORCED: { value: "FORCED", label: "Forcé" },
      EXHIBITION_PRODUCT: {
        value: "EXHIBITION_PRODUCT",
        label: "Produit d'exposition",
      },
    },
  },
  deliveryFailureReasons: {
    absent: "Client absent",
    customerCancellation: "Annulation client",
    addressNotFound: "Adresse non trouvée",
    wrongAddress: "Adresse incorrecte",
    breakdown: "Panne véhicule",
    unknown: "Personne inconnue",
    strike: "Impossible : grève",
    weather: "Impossible : conditions météo",
    damaged: "Produit abimé",
    refused: "Produit refusé par le client",
    partialDelivery: "Colis manquant",
    missingInfo: "Information ou document manquant",
    notready: "Produit non prêt (enlèvement)",
  },
};

function initConfig() {
  return (config = {
    ...globalConfig,
    ...appConfig,
  });
}

initConfig();

export default {
  get: (key) => config && _get(config, key),
  reset: initConfig,
};
