import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ErrorBoundary } from "react-error-boundary";
import CssBaseline from "@material-ui/core/CssBaseline";
import RoutesUsersPage from "./pages/UsersPages";
import RoutesOfficesPage from "./pages/OfficesPages";
import RoutesContractorsPage from "./pages/ContractorsPages";
import RoutesPostalCodesPage from "./pages/PostalCodesPages";
import RoutesArticlesPage from "./pages/ArticlesPage";
import RoutesArticleListPage from "./pages/ArticleListPage";
import RoutesLogsPage from "./pages/LogsPage";
import RoutesAreasPage from "./pages/AreasPages";
import RoutesTrucksPage from "./pages/TrucksPage";
import RoutesTruckLinesPage from "./pages/TruckLinesPages";
import RoutesPlanningPage from "./pages/PlanningPages";
import RoutesOrdersPage from "./pages/OrdersPages/RoutesOrdersPage";
import RoutesOfficePlanningPages from "./pages/OfficePlanningPages/RoutesOfficePlanningPages";
import RoutesRoundPages from "./pages/RoundPages/RoutesRoundPages";
import RoutesRoadmapsBillingPages from "./pages/RoadmapsPages/RoutesRoadmapsBillingPages";
import RoutesBusinessDaysPage from "./pages/BusinessDays/RoutesBusinessDaysPage";
import RoutesUrbantzPage from "./pages/Urbantz/RoutesUrbantzPage";
import RoutesUnderConstruction from "./pages/UnderConstruction/RoutesUnderConstruction";
import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import Header from "./layout/Header";
import theme from "./theme";
import AuthenticatedRoute, { LOGIN_PATH } from "helpers/AuthenticatedRoute";
import UnauthenticatedRoute from "helpers/UnauthenticatedRoute";
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Typography, Box, Button } from "@material-ui/core";
import Notification from "components/shared/Notification";
import Loader from "./components/shared/Loader";
import BoxAlert from "components/shared/BoxAlert";
import { hasValidBrowser } from "helpers/browser";
import { ContentWrapper, GlobalStyle } from "./styled";
import "./theme/fontface.css";
import lazyWithRetry from "helpers/lazyWithRetry";
import { RESET_PASSWORD_PATH } from "./pages/ResetPasswordPage";
import { FORGOT_PASSWORD_PATH } from "./pages/ForgotPasswordPage";
import { SETUP_PASSWORD_PATH } from "./pages/SetupPasswordPage";
import "./main.scss";

const HomePage = lazyWithRetry(() => import("./pages/HomePage"));
const LoginPage = lazyWithRetry(() => import("./pages/LoginPage"));
const ResetPasswordPage = lazyWithRetry(() =>
  import("./pages/ResetPasswordPage")
);
const ForgotPasswordPage = lazyWithRetry(() =>
  import("./pages/ForgotPasswordPage")
);
const SetupPasswordPage = lazyWithRetry(() =>
  import("./pages/SetupPasswordPage")
);

export const history = createBrowserHistory();

const generateClassName = createGenerateClassName({
  developmentPrefix: "dev",
  productionPrefix: "m",
});

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  if (!hasValidBrowser()) {
    return (
      <Box p={2}>
        <BoxAlert pb={2} severity="error">
          <Typography component="h3">
            <b>Une erreur est survenue :</b> &quot;{error.message}&quot;
          </Typography>
          <Typography component="p">
            Il semblerait que vous utilisez un navigateur obsolète. Par
            conséquent, la fonctionnalité que vous venez d&apos;utiliser
            provoque une erreur.
          </Typography>
          <Typography component="p">
            <b>
              Nous vous recommandons de mettre à jour vos outils informatiques
              et d&apos;utiliser une version récente des navigateurs Chrome,
              Firefox ou Edge.
            </b>
          </Typography>
        </BoxAlert>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            resetErrorBoundary();
            history.push("/");
          }}
        >
          Revenir à l&apos;accueil
        </Button>
      </Box>
    );
  }

  return (
    <Box p={2}>
      <BoxAlert pb={2} severity="error">
        <Typography component="h3">
          <b>Une erreur est survenue :</b> &quot;{error.message}&quot;
        </Typography>
      </BoxAlert>
      <Button
        color="secondary"
        variant="contained"
        onClick={resetErrorBoundary}
      >
        Réessayer
      </Button>
    </Box>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  resetErrorBoundary: PropTypes.func,
};

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <StylesProvider generateClassName={generateClassName} injectFirst>
            <MuiThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <CssBaseline />
                <GlobalStyle />
                <Header />
                <Notification />
                <ContentWrapper>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <UnauthenticatedRoute
                        path={SETUP_PASSWORD_PATH}
                        exact
                        component={SetupPasswordPage}
                      />
                      <UnauthenticatedRoute
                        path={FORGOT_PASSWORD_PATH}
                        exact
                        component={ForgotPasswordPage}
                      />
                      <UnauthenticatedRoute
                        path={RESET_PASSWORD_PATH}
                        exact
                        component={ResetPasswordPage}
                      />
                      <UnauthenticatedRoute
                        path={LOGIN_PATH}
                        exact
                        component={LoginPage}
                      />
                      <AuthenticatedRoute>
                        {[
                          [
                            <Route
                              key="homepage"
                              path="/"
                              exact
                              component={HomePage}
                            />,
                          ],
                          RoutesOfficesPage,
                          RoutesUsersPage,
                          RoutesContractorsPage,
                          RoutesPostalCodesPage,
                          RoutesLogsPage,
                          RoutesArticlesPage,
                          RoutesArticleListPage,
                          RoutesAreasPage,
                          RoutesUnderConstruction,
                          RoutesTrucksPage,
                          RoutesTruckLinesPage,
                          RoutesPlanningPage,
                          RoutesOrdersPage,
                          RoutesOfficePlanningPages,
                          RoutesRoundPages,
                          RoutesRoadmapsBillingPages,
                          RoutesBusinessDaysPage,
                          RoutesUrbantzPage,
                        ]}
                      </AuthenticatedRoute>
                    </Switch>
                  </Suspense>
                </ContentWrapper>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </ThemeProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
