import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexContractorsPage = lazyWithRetry(() =>
  import("./IndexContractorsPage")
);
const CreateContractorsPage = lazyWithRetry(() =>
  import("./CreateContractorsPage")
);
const EditContractorsPage = lazyWithRetry(() =>
  import("./EditContractorsPage")
);
const ViewContractorsPage = lazyWithRetry(() =>
  import("./ViewContractorsPage")
);
const CreateContractorAreasPage = lazyWithRetry(() =>
  import("pages/ContractorsPages/CreateContractorAreasPage")
);
const EditContractorAreasPage = lazyWithRetry(() =>
  import("pages/ContractorsPages/EditContractorAreasPage")
);

export default [
  <Route
    exact
    path="/settings/contractors"
    key="contractors-index"
    component={IndexContractorsPage}
  />,
  <Route
    exact
    path="/settings/contractors/create"
    key="contractors-create"
    component={CreateContractorsPage}
  />,
  <Route
    exact
    path="/settings/contractors/edit/:id"
    key="contractors-edit"
    component={EditContractorsPage}
  />,
  <Route
    exact
    path="/settings/contractors/view/:id"
    key="contractors-view"
    component={ViewContractorsPage}
  />,
  <Route
    exact
    path="/settings/contractors/areas/create"
    key="contractors-area-create"
    component={CreateContractorAreasPage}
  />,
  <Route
    exact
    path="/settings/contractors/areas/edit/:areaId"
    key="contractors-area-edit"
    component={EditContractorAreasPage}
  />,
];
