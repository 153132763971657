import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";

export const RESET_PASSWORD_PATH = "/reset-password/:token";

const ResetPasswordPage = (props) => {
  return <ResetPasswordForm {...props} />;
};

export default ResetPasswordPage;
