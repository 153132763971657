import { useContext } from "react";
import { AppDispatchContext } from "../AppContextProviders";

const useAppDispatch = () => {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAppDispatch must be used within a AppDispatchContext.Provider"
    );
  }
  return context;
};

export default useAppDispatch;
