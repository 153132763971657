import { useContext } from "react";
import { AppStateContext } from "../AppContextProviders";

const useAppState = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error(
      "useAppState must be used within a AppStateContext.Provider"
    );
  }
  return context;
};

export default useAppState;
