import styled, { createGlobalStyle } from "styled-components";
import { breakpoint, color } from "./helpers/style/mixins";

export const GlobalStyle = createGlobalStyle`
   /* reduce Mui opacity */
  .MuiInputBase-root.Mui-disabled,
  .MuiFormLabel-root.Mui-disabled,
  .MuiOutlinedInput-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.2);
  }

  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 100%;
  }

  .MuiTooltip-tooltip {
    font-size: 1rem;
    max-width: 100%;

    &.background-hidden{
      background: transparent;
      margin: 0;
      padding: 0;
    }
  }

  /** react-phone-input-2 css overrides */
  .formik-phone-input {
    width: 100%;
  }

  .formik-phone-input .formik-phone-input__error-message {
    position: absolute;
    color: #f44336;
    margin: 3px 14px 0;
    font-size: 0.75rem;
    
  }

  div.react-tel-input .special-label {
    color: rgba(0, 0, 0, 0.54);
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  }

  div.react-tel-input .special-label {
    left: 11px;
  }

  div.react-tel-input:focus-within .special-label {
    color: ${color("primary")};
  }

  div.react-tel-input.error .special-label {
    color: #f44336;
  }


  div.react-tel-input .form-control {
    transition: none;
  }
  div.react-tel-input .form-control:focus:not(.invalid-number) {
    border: 2px solid ${color("primary")};
    box-shadow: none;
  }

  .gmap-marker-label {
    margin-top: 2.1rem;
    margin-left: 1.75rem;
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 90px;
  @media screen and (min-width: ${breakpoint("lg")}) {
    padding-top: 145px;
  }
`;
