import _get from "lodash.get";

// get all properties from theme
// /!\ should replace all others
export const th = (key) => ({ theme }) => _get(theme, key);

// get breakpoint value from material ui theme
// value: xs | sm | md | lg | xl
export const breakpoint = (breakpoint) => ({ theme }) =>
  `${theme.breakpoints.values[breakpoint]}px`;

// get color value from material ui theme
export const color = (key, variant = "main") => ({ theme }) =>
  `${theme.palette[key][variant]}`;

// get spacing values from material ui theme
export const spacing = (spaces) => ({ theme }) =>
  spaces.map((space) => `${theme.spacing(space)}px`).join(" ");

// get font-size values from material ui theme
export const fontSize = (key) => ({ theme }) => `${theme.fontSize[key]}`;
