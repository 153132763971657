import * as yup from "yup";

function isValidPassword() {
  return this.test(
    "is_min_8_if_not_empty",
    "Le mot de passe doit contenir au moins 8 caractères.",
    (password) =>
      password === undefined || password === null || password.length >= 8
  ).test(
    "contains_letter_and_number_if_not_empty",
    "Le mot de passe doit être composé de chiffres et de lettres.",
    (password) =>
      password === undefined ||
      password === null ||
      password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+/)
  );
}

export default () => {
  yup.addMethod(yup.string, "isValidPassword", isValidPassword);
};
