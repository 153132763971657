import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const CreateOfficesPage = lazyWithRetry(() => import("./CreateOfficesPage"));
const IndexOfficesPage = lazyWithRetry(() => import("./IndexOfficesPage"));
const EditOfficesPage = lazyWithRetry(() => import("./EditOfficesPage"));
const AreasOfficePage = lazyWithRetry(() => import("./AreasOfficePage"));

export default [
  <Route
    exact
    path="/settings/offices"
    key="offices-index"
    component={IndexOfficesPage}
  />,
  <Route
    exact
    path="/settings/offices/edit/:id"
    key="offices-edit"
    component={EditOfficesPage}
  />,
  <Route
    exact
    path="/settings/offices/create"
    key="offices-create"
    component={CreateOfficesPage}
  />,
  <Route
    exact
    path="/settings/offices/areas"
    key="offices-areas"
    component={AreasOfficePage}
  />,
];
