import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import useAppState from "helpers/useAppState";
import useAppDispatch from "helpers/useAppDispatch";
import Config from "config";

const Notification = ({ duration = Config.get("notifDefaultDuration") }) => {
  const notif = useAppState().notif;
  const notifMessage = notif.success || notif.error || null;
  const severity = notif.success ? "success" : "error";

  const dispatchNotif = useAppDispatch();

  const handleClose = () => {
    dispatchNotif({ type: "HIDE_NOTIF" });
  };

  const handleExit = () => {
    dispatchNotif({ type: "RESET_NOTIF" });
  };

  return (
    <Snackbar
      open={notif.show}
      autoHideDuration={duration}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExit }}
    >
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {notifMessage}
      </MuiAlert>
    </Snackbar>
  );
};

Notification.propTypes = {
  duration: PropTypes.number,
};

export default Notification;
