import * as yup from "yup";
import enhanceYup from "helpers/enhanceYup";

enhanceYup();

const validationSchema = yup.object().shape({
  password: yup.string().isValidPassword().required("Champs requis"),
  passwordConfirmation: yup
    .string()
    .test(
      "test_password_confirmed",
      "Les mots de passe ne sont pas identiques",
      function (value) {
        return value === this.parent.password;
      }
    )
    .required("Champs requis"),
});

export default validationSchema;
