import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const ArticlesPage = lazyWithRetry(() => import("./ArticlesPage"));

export default [
  <Route
    exact
    path="/settings/articles"
    key="articles"
    component={ArticlesPage}
  />,
];
