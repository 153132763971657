import React from "react";
import {
  Description as DescriptionIcon,
  Home as HomeIcon,
  LocalShipping as LocalShippingIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import Config from "config";

import { TearOfCalendarIcon, Backnotes } from "components/shared/icons";
import { userRoles } from "../../helpers/userRoles";

const sortByPosition = (items) => {
  const sortedNavbar = items.mainNav.sort((a, z) => a.position - z.position);

  return { ...items, items: { mainNav: sortedNavbar } };
};

const menuItemsContractor = {
  mainNav: [
    {
      title: "accueil",
      href: "/",
      icon: <HomeIcon />,
      isNavLink: true,
      position: 1,
    },
    {
      title: "commandes",
      href: "/orders",
      icon: <DescriptionIcon />,
      isNavLink: true,
      position: 2,
    },
  ],
  settingsLinks: [],
  status: [],
};

const menuItemsUser = {
  mainNav: [
    ...menuItemsContractor.mainNav,
    {
      title: "tournées",
      href: "/rounds",
      icon: <LocalShippingIcon />,
      isNavLink: true,
      position: 3,
    },
    {
      title: "planning d'agence",
      href: "/office-planning",
      icon: <TearOfCalendarIcon />,
      isNavLink: true,
      position: 4,
    },
    {
      title: "facturation",
      href: "/roadmaps/billing",
      icon: <Backnotes />,
      isNavLink: true,
      position: 5,
    },
    {
      title: "paramètres",
      href: "/settings",
      icon: <SettingsIcon />,
      isNavLink: false,
      position: 6,
    },
  ],
  settingsLinks: [
    { text: "magasins", href: "/settings/contractors" },
    { text: "lignes camions", href: "/settings/trucklines" },
  ],
  status: [],
};

const menuItemsManager = {
  mainNav: [...menuItemsUser.mainNav],
  settingsLinks: [
    ...menuItemsUser.settingsLinks,
    { text: "planning", href: "/settings/planning" },
    { text: "camions", href: "/settings/trucks" },
  ],
  status: [],
};

const menuItemsAdmin = {
  mainNav: [...menuItemsManager.mainNav],
  settingsLinks: [
    ...menuItemsManager.settingsLinks,
    { text: "gestion agences", href: "/settings/offices" },
    { text: "gestion utilisateurs", href: "/settings/users" },
    { text: "jours de fermeture", href: "/settings/business-days" },
    { text: "articles", href: "/settings/articles" },
    { text: "liste d'articles", href: "/settings/article-lists" },
    { text: "codes postaux", href: "/settings/postcodes" },
    { text: "secteurs", href: "/settings/offices/areas" },
  ],
  status: [
    // { text: "logs utilisateurs", href: "/logs/users", icon: <NotesIcon /> },
  ],
};

if (Config.get("isUrbantzEnabled")) {
  menuItemsAdmin.settingsLinks.push({
    text: "urbantz",
    href: "/settings/urbantz",
  });
}

export const menuLabels = {
  settings: "paramètres",
  logs: "logs",
  logout: "déconnexion",
};

export const getMenuByProfile = (profile) => {
  switch (profile) {
    case userRoles.CONTRACTOR:
      return sortByPosition(menuItemsContractor);
    case userRoles.USER:
      return sortByPosition(menuItemsUser);
    case userRoles.MANAGER:
      return sortByPosition(menuItemsManager);
    case userRoles.ADMIN:
    case userRoles.SUPER_ADMIN:
      return sortByPosition(menuItemsAdmin);
    default:
      return menuItemsContractor;
  }
};

export const menuItems = {
  ...sortByPosition(menuItemsAdmin),
};
