import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexTruckLinesPage = lazyWithRetry(() =>
  import("./IndexTruckLinesPage")
);
const CreateTruckLinesPage = lazyWithRetry(() =>
  import("./CreateTruckLinesPage")
);
const EditTruckLinesPage = lazyWithRetry(() => import("./EditTruckLinesPage"));

export default [
  <Route
    exact
    path="/settings/trucklines"
    key="trucklines-index"
    component={IndexTruckLinesPage}
  />,
  <Route
    exact
    path="/settings/trucklines/create"
    key="trucklines-create"
    component={CreateTruckLinesPage}
  />,
  <Route
    exact
    path="/settings/trucklines/edit/:id"
    key="trucklines-edit"
    component={EditTruckLinesPage}
  />,
];
