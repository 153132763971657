import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const IndexPostalCodesPage = lazyWithRetry(() =>
  import("./IndexPostalCodesPage")
);
const CreatePostalCodesPage = lazyWithRetry(() =>
  import("./CreatePostalCodesPage")
);
const EditPostalCodesPage = lazyWithRetry(() =>
  import("./EditPostalCodesPage")
);

export default [
  <Route
    exact
    path="/settings/postcodes"
    key="postcodes-index"
    component={IndexPostalCodesPage}
  />,
  <Route
    exact
    path="/settings/postcodes/create"
    key="postcodes-create"
    component={CreatePostalCodesPage}
  />,
  <Route
    exact
    path="/settings/postcodes/edit/:id"
    key="postcodes-edit"
    component={EditPostalCodesPage}
  />,
];
