import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, CircularProgress } from "@material-ui/core";

const Loader = ({ size = 50, marginTop = 1 }) => (
  <Box mt={marginTop}>
    <Grid container justifyContent="center" alignItems="center">
      <CircularProgress size={size} />
    </Grid>
  </Box>
);

Loader.propTypes = {
  size: PropTypes.number,
  marginTop: PropTypes.number,
};

export default Loader;
