import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import MuiListItemIcon from "@material-ui/core/ListItemIcon";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiAccordionPanel from "@material-ui/core/Accordion";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import { AppBar as BaseAppBar } from "@material-ui/core";
import { Link } from "react-router-dom";

import { breakpoint, color, spacing } from "../../helpers/style/mixins";

export const AppBarWrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  display: flex;
  width: 100%;
`;

export const AppBar = styled(BaseAppBar)`
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.tertiary.main};
`;

export const NavbarGrid = styled(Grid)`
  .main-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .logo {
    display: flex;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 4px;
    padding-bottom: 4px;

    &__office {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .info-office__label {
        padding-right: ${({ theme }) => theme.spacing(1)}px;
      }
      .Mui-disabled {
        background: #ddd;
      }
    }

    .MuiSelect-root {
      font-size: 12px;
      min-width: 200px;
    }

    .MuiOutlinedInput-input {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        z-index: -1;
        border-color: ${color("grey", 300)};
        background: ${color("primary", "contrastText")};
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border: 0;
      }

      @media screen and (min-width: ${breakpoint("lg")}) {
        border-color: red;
      }
    }
  }

  .menu-icon {
    display: flex;
    justify-content: flex-end;
  }
`;

export const LinkButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0 8px 0 2px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  font-size: 12px;
  text-decoration: none;
  color: ${color("primary", "contrastText")};
  background: ${color("primary")};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  transition: background 0.3s, color 0.3s;
  &:first-child {
    margin-left: ${spacing([2])};
  }
  &:not(:last-child) {
    margin-right: ${spacing([1])};
  }

  .MuiSvgIcon-root {
    height: 14px;
  }
`;

export const LinkButtonText = styled.span`
  display: block;
`;

export const MainNavButton = styled(Fab)`
  width: ${({ theme }) => theme.sizes.mainNavButtonSize};
  height: ${({ theme }) => theme.sizes.mainNavButtonSize};
  border-radius: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  background: transparent;
  box-shadow: none;
  :hover,
  :active,
  &.active {
    background: ${({ theme }) => theme.palette.secondary.main};
    box-shadow: none;
  }
`;

export const StatusBar = styled.div`
  display: flex;
  min-height: 40px;
  justify-content: flex-end;
  align-items: center;
  > *:first-child {
    margin-right: auto;
  }
  padding-left: ${spacing([2])};
  padding-right: ${spacing([2])};
  background: ${({ theme }) => theme.palette.tertiary.main};

  a {
    &:hover {
      background: ${color("primary", "dark")};
    }
  }

  .banner-announcement {
    font-size: 1.2rem;
    &:hover {
      background: none;
    }
  }
`;

export const SettingsNav = styled.div`
  display: flex;
  min-height: 40px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.palette.tertiary.main};
  box-shadow: inset 0 2px 5px rgba(40, 40, 40, 0.3),
    inset 0 -2px 5px rgba(40, 40, 40, 0.3);
  a {
    padding-right: ${({ theme }) => theme.spacing(1)}px;
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    &:hover {
      background: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  @media screen and (min-width: ${breakpoint("lg")}) {
    justify-content: center;
  }

  .logoLink {
    display: flex;
    align-items: center;
  }

  .logoImg {
    width: ${({ theme }) => theme.sizes.logoWidth};
  }
`;

export const MenuTouchDrawer = styled(Drawer)`
  width: ${({ theme }) => theme.sizes.menuTouchWidth}
  box-shadow: ${({ theme }) => theme.shadows[2]};

  .MuiDrawer-paper {
    background: ${({ theme }) => theme.palette.tertiary.main};
  }

  .drawerHeader {
    display: flex;
    align-items: center;
    padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
    justify-content: flex-start;
    z-index: 1;
    ${({ theme }) => theme.mixins.toolbar};
  }

  .menu-touch-link {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
  }
`;

export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 0;
  padding-right: ${({ theme }) => `${theme.spacing(2)}px`};
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ListItemText = styled(MuiListItemText)`
  .MuiTypography-root {
    font-size: 14px;
  }
`;

export const AccordionPanel = styled(MuiAccordionPanel)`
  box-shadow: none;
  width: 100%;
  background: transparent;

  .MuiAccordionSummary {
    &-root {
      padding: 0;
      min-height: 0;
    }
    &-content {
      margin: 0;
    }
    &-expandIcon {
      padding: 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiList-root {
    width: 100%;
  }
`;
