import React, { useState, useEffect } from "react";
import { isToday, format } from "date-fns";
import { fr } from "date-fns/locale";

const Today = () => {
  const [today, setToday] = useState(new Date(Date.now()));

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isToday(today)) {
        setToday(new Date(Date.now()));
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [today]);

  return (
    <div>
      {format(today, "EEEE dd LLLL yyyy - 'semaine' I", { locale: fr })}
    </div>
  );
};

export default Today;
