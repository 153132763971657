import React from "react";
import PropTypes from "prop-types";
import useAppState from "helpers/useAppState";
import { userRoles } from "helpers/userRoles";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { color } from "helpers/style/mixins";

export const ME_PATH = "/settings/users/me";

const hasValidIdentity = (identity) => {
  return identity?.firstName && identity?.lastName;
};

const canEditOwnUserInfo = (profile) => {
  return [
    userRoles.SUPER_ADMIN,
    userRoles.ADMIN,
    userRoles.MANAGER,
    userRoles.USER,
  ].includes(profile);
};

const StyledLink = styled(Link)`
  color: ${color("text", "primary")};
`;

const Identity = ({ identity: { firstName, lastName }, canEdit = false }) => {
  const fullIdentity = `${firstName} ${lastName}`;
  return canEdit ? (
    <StyledLink to={ME_PATH}>
      <b>{fullIdentity}</b>
    </StyledLink>
  ) : (
    <b>{fullIdentity}</b>
  );
};

Identity.propTypes = {
  identity: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  canEdit: PropTypes.bool,
};

const ConnectedUser = () => {
  const { identity, profile } = useAppState().user;

  return hasValidIdentity(identity) ? (
    <span className="connected-user">
      Connecté :{" "}
      <Identity identity={identity} canEdit={canEditOwnUserInfo(profile)} />
    </span>
  ) : null;
};

export default ConnectedUser;
