import PropTypes from "prop-types";
import React from "react";

export const ScrewdriverLock = ({ color = "#20274a", lineWidth = "2" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform="scale(1.25)"
  >
    <path
      d="M17.9993 1.83002C17.4993 1.83002 16.9993 2.00002 16.5893 2.41002L7.99927 11L9.49927 12.5L5.99927 16H3.99927L1.99927 20L3.99927 22L7.99927 20V18L11.4993 14.5L12.9993 16L21.5893 7.41002C22.2093 6.50002 22.3693 5.37002 21.5893 4.59002L19.4093 2.41002C19.2252 2.2236 19.0056 2.07616 18.7633 1.97651C18.521 1.87686 18.2612 1.82704 17.9993 1.83002V1.83002ZM17.9993 4.00002L19.9993 6.00002L12.9993 13L10.9993 11L17.9993 4.00002Z"
      fill={color}
    />
    <g filter="url(#filter0_d)">
      <line
        x1="4.70711"
        y1="3.29289"
        x2="20.9706"
        y2="19.5563"
        stroke={color}
        strokeWidth={lineWidth}
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="4"
        y="1.58578"
        width="18.6777"
        height="18.6777"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="1" dy="-1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

ScrewdriverLock.propTypes = {
  color: PropTypes.string,
  lineWidth: PropTypes.string,
};
