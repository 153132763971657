import React from "react";
import ResetPasswordPage from "pages/ResetPasswordPage";

export const SETUP_PASSWORD_PATH = "/setup-password/:token";

const SetupPasswordPage = (props) => {
  return <ResetPasswordPage {...props} isInitialization={true} />;
};

export default SetupPasswordPage;
