import React from "react";
import { Route } from "react-router-dom";
import lazyWithRetry from "helpers/lazyWithRetry";

const ArticleListPage = lazyWithRetry(() => import("./ArticleListPage"));
const EditArticleListPage = lazyWithRetry(() =>
  import("./EditArticleListPage")
);

export default [
  <Route
    exact
    path="/settings/article-lists"
    key="article-lists"
    component={ArticleListPage}
  />,
  <Route
    exact
    path="/settings/article-lists/edit/:id"
    key="article-lists-edit"
    component={EditArticleListPage}
  />,
];
